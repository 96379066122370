import styled from 'styled-components';
import { useCallback, useEffect, useRef } from 'react';

import {
  ButtonUnstyled,
  ChevronLeftIcon,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Stack,
  useDisclosure,
} from '@whiteaway/ui';

import { ProtectedHeaderSearchInput } from './protected-header-search-input';
import { ProtectedHeaderSearchSuggestions } from './protected-header-search-suggestions';
import { useProtectedHeaderSearchContext } from './protected-header-search.context';
import { ProtectedHeaderSearchSuggestion } from './protected-header-search-suggestion';

/**
 * The component contains the drawer that is triggered updating search query.
 */
export const ProtectedHeaderSearchDrawer: React.FC = (props) => {
  const { searchSuggestions } = useProtectedHeaderSearchContext();

  const { open: openDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure();

  const secondInputRef = useRef<HTMLInputElement>(null);

  const { inputValue } = useProtectedHeaderSearchContext();

  // The drawer is not in the DOM and therefore we can't immediately focus it, so we need to wait a bit for it to be appended
  const focusTimeout = 50;

  // This hook opens the drawer whenever the search field value length greater than 0
  useEffect(() => {
    if (inputValue.length !== 0) {
      onOpenDrawer();
    } else {
      onCloseDrawer();
    }
  }, [inputValue.length, onCloseDrawer, onOpenDrawer]);

  const openDrawerClick = useCallback(() => {
    onOpenDrawer();

    setTimeout(() => {
      secondInputRef.current?.focus();
    }, focusTimeout);
  }, [onOpenDrawer, secondInputRef]);

  return (
    <Root {...props}>
      <ProtectedHeaderSearchInput onClick={openDrawerClick} />

      <Drawer placement="left" onClose={onCloseDrawer} open={openDrawer} size="full">
        <DrawerContent>
          <DrawerHeader>
            <Stack direction="row" align="center" spacing={3}>
              <ButtonUnstyled onClick={onCloseDrawer}>
                <ChevronLeftIcon />
              </ButtonUnstyled>

              <ProtectedHeaderSearchInput ref={secondInputRef} />
            </Stack>
          </DrawerHeader>

          <DrawerBody>
            <ProtectedHeaderSearchSuggestions>
              {searchSuggestions.map((searchSuggestion, index) => (
                <ProtectedHeaderSearchSuggestion
                  onClick={() => onCloseDrawer()}
                  key={`${searchSuggestion.sku}-${index}`}
                  searchSuggestion={searchSuggestion}
                />
              ))}
            </ProtectedHeaderSearchSuggestions>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Root>
  );
};

export default ProtectedHeaderSearchDrawer;

const Root = styled.div`
  position: relative;
  grid-area: search;
`;
