import { DescendantsProvider, Stack, StackProps } from '@whiteaway/ui';

interface Props extends React.PropsWithChildren, StackProps {}

/**
 * The component is used to render out a menu inside of a drawer.
 * For instance, inside the categeries menu drawer and the profile drawer.
 */
export const DrawerMenu: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  return (
    <DescendantsProvider>
      <Stack role="menu" {...rest}>
        {children}
      </Stack>
    </DescendantsProvider>
  );
};
