import React from 'react';
import styled, { css } from 'styled-components';

import { MenuIcon, callAllHandlers, IconButton, IconButtonProps } from '@whiteaway/ui';

import { useProtectedHeader } from '../protected-header.context';

interface Props extends IconButtonProps {}

/**
 * The component contains the button that is used to open/close the mobile menu.
 */
export const ProtectedHeaderMobileMenu: React.FC<Props> = (props) => {
  const { onClick, ...rest } = props;

  const { onOpenCategoriesDrawer } = useProtectedHeader();

  return (
    <Root variant="subtle" size="sm" onClick={callAllHandlers(onClick, onOpenCategoriesDrawer)} {...rest}>
      <MenuIcon />
    </Root>
  );
};

const Root = styled(IconButton)`
  ${({ theme }) => css`
    grid-area: mobile-menu;
    width: ${theme.getSpacing(14)};

    ${theme.breakpoints.above('desktop')} {
      display: none;
    }
  `}
`;
