import styled from 'styled-components';

import { Typography } from '@whiteaway/ui';

import { useTranslations } from '@/contexts';

import { useProtectedHeaderSearchContext } from './protected-header-search.context';

interface Props extends React.HTMLAttributes<HTMLUListElement> {}

/**
 * The component holds the list with the search ahead suggestions.
 */
export const ProtectedHeaderSearchSuggestions: React.FC<Props> = (props) => {
  const { children } = props;

  const { translations } = useTranslations();
  const texts = translations.layouts.protected.header.search.suggestions;

  const { searchSuggestions } = useProtectedHeaderSearchContext();

  if (searchSuggestions.length === 0) return <NoResults>{texts.noResults}</NoResults>;

  return (
    <Root role="listbox" aria-label="search-results" {...props}>
      {children}
    </Root>
  );
};

const Root = styled.ul`
  padding: 0;
`;

const NoResults = styled(Typography)`
  padding: ${(props) => props.theme.getSpacing(3, 6)};
`;
