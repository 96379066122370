'use client';

import styled, { css } from 'styled-components';

import { Button, MenuIcon } from '@whiteaway/ui';

import { useTranslations } from '@/contexts';

import { useProtectedHeader } from '../protected-header.context';

/**
 * The component is used to display to show a menu with one button that opens the categories drawer.
 */
export const ProtectedHeaderDesktopMenu: React.FC = () => {
  const { translations } = useTranslations();
  const texts = translations.layouts.protected.header;

  const { onOpenCategoriesDrawer } = useProtectedHeader();

  return (
    <Root>
      <Button size="sm" onClick={onOpenCategoriesDrawer}>
        <MenuIcon />
        {texts.openMenuButton}
      </Button>
    </Root>
  );
};

export default ProtectedHeaderDesktopMenu;

const Root = styled.div`
  ${({ theme }) => css`
    margin: ${theme.getSpacing(2, 0, 3)};

    ${theme.breakpoints.below('desktop')} {
      display: none;
    }
  `};
`;
