import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter as useNextRouter, usePathname } from 'next/navigation';
import { useCallback } from 'react';
import NProgress from 'nprogress';

/**
 * The hook extends Next.js's native useRouter hook by integrating navigation progress feedback using NProgress.
 */
export const useRouter = () => {
  const router = useNextRouter();
  const pathname = usePathname();

  const push = useCallback(
    (href: string, options?: NavigateOptions) => {
      if (href !== pathname) {
        NProgress.start();
      }
      router.push(href, options);
    },
    [router, pathname],
  );

  return {
    ...router,
    push,
  };
};
