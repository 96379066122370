import { useState } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import {
  Slide,
  Button,
  Stack,
  CloseIcon,
  IconButton,
  Typography,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@whiteaway/ui';

import { Category } from '@/bff-client';
import { translations } from '@/contexts';
import { DrawerMenuItem, DrawerMenuItemLabel } from '@/components';

import { useProtectedHeader } from '../protected-header.context';

interface Props {
  /**
   * The specific category for the item.
   */
  category: Category;
  /**
   * The ref to the drawer, since we need to create the slide animation around that.
   */
  drawerRef: React.RefObject<HTMLDivElement | null>;
  /**
   * The icon for the item.
   */
  icon?: React.ReactNode;
  /**
   * Callback that is fired when a link is clicked.
   * This is needed to activate the toploader on the page on navigation.
   */
  onLinkClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
  /**
   * The recursive path for the category.
   * This is used to create the correct path for the category, using all the previous parent categories.
   */
  path: string;
  /**
   * The previous category name.
   */
  prevCategoryName: string;
}

/**
 * The component renders a single item for the categories drawer inside the header.
 * Each item will have an underlying menu with all its attached subcategories.
 */
export const ProtectedHeaderCategoriesDrawerItem: React.FC<Props> = (props) => {
  const { category, icon, drawerRef, prevCategoryName, path, onLinkClick } = props;

  const [openSlideMenu, setOpenSlideMenu] = useState(false);

  const { onCloseCategoriesDrawer } = useProtectedHeader();

  const texts = translations.layouts.protected.header.categoriesDrawer.item;

  /**
   * If the category does not have any subcategories, we will render a simple link.
   */
  if (!category.subCategories) {
    return (
      <DrawerMenuItem asElement={Link} href={path} onClick={onLinkClick}>
        {icon}

        <DrawerMenuItemLabel>{category.name}</DrawerMenuItemLabel>
      </DrawerMenuItem>
    );
  }

  return (
    <>
      <DrawerMenuItem asElement="button" onClick={() => setOpenSlideMenu(true)}>
        {icon}

        <DrawerMenuItemLabel>{category.name}</DrawerMenuItemLabel>

        <ChevronRightIcon />
      </DrawerMenuItem>

      <Slide unmountOnExit direction="left" in={openSlideMenu} container={drawerRef as React.RefObject<HTMLDivElement>}>
        <SlideMenuRoot spacing={3}>
          <Stack spacing={2} direction="row" align="center" justify="space-between">
            <Button variant="text" size="sm" onClick={() => setOpenSlideMenu(false)}>
              <ChevronLeftIcon />
              {prevCategoryName}
            </Button>

            <IconButton size="sm" color="neutral" variant="subtle" onClick={onCloseCategoriesDrawer}>
              <CloseIcon />
            </IconButton>
          </Stack>

          <Typography variant="h2">{category.name}</Typography>

          <Stack spacing={0}>
            {category.subCategories?.map((subCategory) => (
              <ProtectedHeaderCategoriesDrawerItem
                key={subCategory.id}
                drawerRef={drawerRef}
                category={subCategory}
                onLinkClick={onLinkClick}
                path={`${path}/${subCategory.slug}`}
                prevCategoryName={category.name || ''}
              />
            ))}

            <DrawerMenuItem href={path} onClick={onLinkClick}>
              <DrawerMenuItemLabel>{texts.allProducts}</DrawerMenuItemLabel>
            </DrawerMenuItem>
          </Stack>
        </SlideMenuRoot>
      </Slide>
    </>
  );
};

const SlideMenuRoot = styled(Stack)`
  ${({ theme }) => css`
    inset: 0;
    z-index: 1;
    position: absolute;

    padding: ${theme.getSpacing(4)};
    background-color: ${theme.colors.background.default};
  `}
`;
