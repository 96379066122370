import { GTM_EVENTS } from '@/config';

import { trackGtmEvent } from '../track-gtm-event';

/**
 * This event is used to track the search term input by the user.
 */
export type SearchEvent = {
  event: GTM_EVENTS.SEARCH;
  options: {
    /**
     * The search term input by the user.
     */
    search_term: string;
  };
};

/**
 * Tracks the search term input by the user.
 *
 * @param searchTerm The search term input by the user.
 */
export const trackSearchEvent = (searchTerm: string) => {
  trackGtmEvent({
    event: GTM_EVENTS.SEARCH,
    options: {
      search_term: searchTerm,
    },
  });
};
