import { CartItem } from '@/bff-client';

import { EventItem } from './track-gtm-event';

/**
 * The product to track in the event.
 * It is a boiled down mix of the ProductSearchProduct and Product interfaces.
 */
export interface TrackingEventProduct {
  /**
   * The sku for the product.
   */
  sku: string;
  /**
   * The name of the product.
   */
  name: string;
  /**
   * The brand of the product.
   */
  brand: string;
  /**
   * The price of the product.
   */
  price: number;
  /**
   * The quantity of the product.
   */
  quantity?: number;
  /**
   * The index of the product.
   */
  index?: number;
  /**
   * The categories of the product.
   */
  categories: { displayName: string }[];
}

/**
 * Maps the products to the event items.
 * @param products The products to map.
 * @returns The products mapped to the EventItem type.
 */
export const mapProductsToEventItems = (products: TrackingEventProduct[], quantity: number = 1): EventItem[] => {
  return products.map((product, index) => {
    return {
      item_id: product.sku,
      item_name: product.name,
      item_brand: product.brand,
      price: product.price,
      quantity,
      index,
      ...(product.categories[0] && { item_category: product.categories[0].displayName }),
      ...(product.categories[1] && { item_category2: product.categories[1].displayName }),
      ...(product.categories[2] && { item_category3: product.categories[2].displayName }),
      ...(product.categories[3] && { item_category4: product.categories[3].displayName }),
      ...(product.categories[4] && { item_category5: product.categories[4].displayName }),
    };
  });
};

/**
 * Maps the cart items to the event items.
 * @param cartItems The products to map.
 * @returns The cartItems mapped to the EventItem type.
 */
export const mapCartItemsToEventItems = (cartItems: CartItem[]): EventItem[] => {
  return cartItems.map((cartItem, index) => {
    return {
      item_id: cartItem.sku,
      item_name: cartItem.name,
      item_brand: cartItem.brand,
      price: cartItem.unitPrice,
      quantity: cartItem.quantity,
      index,
    };
  });
};
