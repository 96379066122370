import styled, { css } from 'styled-components';

import {
  Drawer,
  useViewport,
  DrawerProps,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody as ArcadeDrawerBody,
} from '@whiteaway/ui';

interface Props extends DrawerProps {}

/**
 * The component holds the basic setup for the drawers inside the protected header.
 * That is, the categories and profile drawer.
 */
export const ProtectedHeaderDrawer: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  const { isMobile } = useViewport();

  return (
    <Drawer size={isMobile ? 'full' : 'md'} {...rest}>
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const DrawerBody = styled(ArcadeDrawerBody)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    gap: ${theme.spacings[6]};
    padding-top: ${theme.getSpacing(10)};
    background-color: ${theme.colors.background.default};
  `}
`;
