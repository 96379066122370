export const testIds = {
  pages: {
    signin: {
      emailInput: 'email-input',
      passwordInput: 'password-input',
      loginButton: 'login-button',
    },
    categories: {
      productImage: 'product-image',
    },
    cart: {
      continueButton: 'continue-button',
    },
    delivery: {
      continueButton: 'continue-button',
    },
    services: {
      checkoutButton: 'checkout-button',
    },
    orderConfirmation: {
      confirmationMessage: 'confirmation-message',
    },
  },
  components: {
    protectedHeader: {
      cartLink: 'cart-link',
    },
  },
  layouts: {},
  shared: {
    atbButton: 'atb-button',
  },
};
