import { Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { useCart } from '@/contexts';
import { Circle } from '@/components';

const MAX_QUANTITY = 99;

interface Props extends React.HTMLAttributes<HTMLSpanElement> {}

/**
 * The component holds the counter that is displayed next to the cart icon inside the header.
 * It will convey the number of items inside of the card.
 */
export const ProtectedHeaderActionsCounter: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  const { cartQuantity } = useCart();
  const quantity = cartQuantity > MAX_QUANTITY ? `${MAX_QUANTITY}+` : cartQuantity;

  return (
    <Root {...rest}>
      {children}

      {cartQuantity > 0 && (
        <Fragment key={cartQuantity}>
          <Counter size={24} background="primary.default">
            {quantity}
          </Counter>
        </Fragment>
      )}
    </Root>
  );
};

const COUNTER_ANIMATION = keyframes`
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  50% {
    transform: scale(0.8) translate(50%, -50%);
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
`;

const Root = styled.span`
  flex-shrink: 0;
  position: relative;
  display: inline-flex;
`;

const Counter = styled(Circle)`
  ${({ theme }) => css`
    position: absolute;
    top: -${theme.getSpacing(1)};
    right: -${theme.getSpacing(1)};
    transform-origin: 100% 0%;
    transform: scale(1) translate(50%, -50%);

    animation-name: ${COUNTER_ANIMATION};
    animation-duration: ${theme.transitions.duration.fast}ms;

    color: ${theme.colors.primary.contrastText};

    ${{ ...theme.typography.caption }};
  `}
`;
