import { DescendantsProvider, useViewport } from '@whiteaway/ui';

import { ProtectedHeaderSearchDrawer } from './protected-header-search-drawer';
import { ProtectedHeaderSearchOverlay } from './protected-header-search-overlay';
import { ProtectedHeaderSearchProvider } from './protected-header-search.context';

/**
 * The component contains the different versions of the search results, drawer on mobile and overlay on desktop.
 */
export const ProtectedHeaderSearch: React.FC = () => {
  const { isDesktop } = useViewport();

  return (
    <DescendantsProvider>
      <ProtectedHeaderSearchProvider>
        {isDesktop && <ProtectedHeaderSearchOverlay />}

        {!isDesktop && <ProtectedHeaderSearchDrawer />}
      </ProtectedHeaderSearchProvider>
    </DescendantsProvider>
  );
};

export default ProtectedHeaderSearch;
