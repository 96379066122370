import styled, { css } from 'styled-components';

import { Fade, Grow, Portal } from '@whiteaway/ui';

import { useProtectedHeaderSearchOverlayContext } from './protected-header-search-overlay';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * The component holds the overlay for the search suggestions.
 */
export const ProtectedHeaderSearchMenu: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  const { openOverlay, onCloseOverlay } = useProtectedHeaderSearchOverlayContext();

  return (
    <>
      <Portal>
        <Fade in={openOverlay} unmountOnExit>
          <Overlay role="presentation" onClick={() => onCloseOverlay()} />
        </Fade>
      </Portal>

      <Grow in={openOverlay} {...rest}>
        <Root tabIndex={-1} {...rest}>
          {children}
        </Root>
      </Grow>
    </>
  );
};

const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: absolute;

    max-height: 80vh;
    overflow-y: auto;

    z-index: ${theme.zIndexes.sticky};
    box-shadow: ${theme.shadows.floating};
    background-color: ${theme.colors.background.subtle};

    border-bottom-left-radius: ${theme.radii.md};
    border-bottom-right-radius: ${theme.radii.md};
  `};
`;

const Overlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);

  inset: 0;
  top: 100px;
  z-index: ${(props) => props.theme.zIndexes.sticky - 1};
`;
