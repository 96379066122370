'use client';

import React, { createContext, useContext } from 'react';

import { useDisclosure } from '@whiteaway/ui';

import { Category, Profile } from '@/bff-client';

export interface ProtectedHeaderContextProps extends React.PropsWithChildren {
  /**
   * The categories for the menu inside the header.
   */
  categories: Category[];
  /**
   * The profile for the user.
   */
  profile: Profile;
}

export interface ProtectedHeaderContextValues extends ProtectedHeaderContextProps {
  /**
   * Callback that is used to close the categories drawer.
   */
  onCloseCategoriesDrawer: () => void;
  /**
   * Callback that is used to close the profile drawer.
   */
  onCloseProfileDrawer: () => void;
  /**
   * Callback that is used to open the categories drawer.
   */
  onOpenCategoriesDrawer: () => void;
  /**
   * Callback that is used to open the profile drawer.
   */
  onOpenProfileDrawer: () => void;
  /**
   * Determines whether the categories drawer should be open.
   */
  openCategoriesDrawer: boolean;
  /**
   * Determines whether the profile drawer should be open.
   */
  openProfileDrawer: boolean;
}

export const ProtectedHeaderContext = createContext<ProtectedHeaderContextValues>({} as ProtectedHeaderContextValues);

/**
 * The context for the protected header component.
 */
export const ProtectedHeaderProvider = (props: ProtectedHeaderContextProps) => {
  const { children, categories, profile } = props;

  const {
    open: openCategoriesDrawer,
    onOpen: onOpenCategoriesDrawer,
    onClose: onCloseCategoriesDrawer,
  } = useDisclosure();

  const { open: openProfileDrawer, onClose: onCloseProfileDrawer, onOpen: onOpenProfileDrawer } = useDisclosure();

  return (
    <ProtectedHeaderContext.Provider
      value={{
        profile,
        categories,
        openProfileDrawer,
        onOpenProfileDrawer,
        openCategoriesDrawer,
        onCloseProfileDrawer,
        onOpenCategoriesDrawer,
        onCloseCategoriesDrawer,
      }}
    >
      {children}
    </ProtectedHeaderContext.Provider>
  );
};

export const useProtectedHeader = () => useContext(ProtectedHeaderContext);
