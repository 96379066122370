import { useCallback } from 'react';
import { signOut } from 'next-auth/react';

import {
  Icon,
  Stack,
  Button,
  NoteIcon,
  HelpIcon,
  Typography,
  LogoutIcon,
  ReceiptIcon,
  PersonAddIcon,
} from '@whiteaway/ui';

import { ROUTES } from '@/config';
import { useRouter } from '@/hooks';
import { useTranslations } from '@/contexts';

import {
  ProtectedHeaderDrawer,
  ProtectedHeaderDrawerMenu,
  ProtectedHeaderDrawerMenuItem,
  ProtectedHeaderDrawerMenuItemLabel,
} from '../_components/protected-header-drawer';
import { useProtectedHeader } from '../protected-header.context';

const MENU_ITEMS = [
  {
    name: 'orders',
    href: ROUTES.PROTECTED.ORDERS,
    icon: NoteIcon,
  },
  {
    name: 'invoices',
    href: ROUTES.PROTECTED.INVOICES,
    icon: ReceiptIcon,
  },
  {
    name: 'inviteUser',
    href: ROUTES.PROTECTED.INVITE_USER,
    icon: PersonAddIcon,
  },
  {
    name: 'support',
    href: ROUTES.PROTECTED.SUPPORT,
    icon: HelpIcon,
  },
];

/**
 * The component holds a drawer with the profile links for the user.
 */
export const ProtectedHeaderProfileDrawer: React.FC = () => {
  const { translations } = useTranslations();
  const texts = translations.layouts.protected.header.profileDrawer;

  const { profile, openProfileDrawer, onCloseProfileDrawer } = useProtectedHeader();

  const router = useRouter();

  const handleLogout = useCallback(async () => {
    // Using async/await ensures that the signOut process completes before navigating to the sign-in page.
    await signOut();

    router.push(ROUTES.AUTH.SIGNIN);
  }, [router]);

  /** Clicking links within ProtectedHeaderCategoriesDrawer
   * and ProtectedHeaderProfileDrawer currently fails to activate
   * the Toploader due to stopPropagation used in the Arcade Modal.
   * For now the Toploader is instead activated using router.push
   * TODO BO-956 */
  const handleClick = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      event.preventDefault();

      const href = event.currentTarget.getAttribute('href');

      if (href) {
        router.push(href);
        onCloseProfileDrawer();
      }
    },
    [onCloseProfileDrawer, router],
  );

  return (
    <ProtectedHeaderDrawer open={openProfileDrawer} onClose={onCloseProfileDrawer}>
      <Stack spacing={1}>
        <Typography variant="h2">{texts.header}</Typography>

        <Typography>{profile.email}</Typography>

        <Button variant="text" size="sm" onClick={handleLogout}>
          <LogoutIcon />
          {texts.logout}
        </Button>
      </Stack>

      <ProtectedHeaderDrawerMenu>
        {MENU_ITEMS.map(({ name, icon, href }) => (
          <ProtectedHeaderDrawerMenuItem key={name} onClick={handleClick} href={href}>
            <Icon asElement={icon} />

            <ProtectedHeaderDrawerMenuItemLabel>
              {texts.menuItems[name as keyof typeof texts.menuItems]}
            </ProtectedHeaderDrawerMenuItemLabel>
          </ProtectedHeaderDrawerMenuItem>
        ))}
      </ProtectedHeaderDrawerMenu>
    </ProtectedHeaderDrawer>
  );
};
