import styled from 'styled-components';

import { HeaderLogo } from '@/components';

/**
 * The component contains the logo inside the protected header.
 */
export const ProtectedHeaderLogo: React.FC = () => {
  return <Root />;
};

const Root = styled(HeaderLogo)`
  grid-area: logo;
  justify-self: center;
`;
