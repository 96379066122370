import Link from 'next/link';
import styled, { css } from 'styled-components';
import { useCallback } from 'react';

import { useDescendant } from '@whiteaway/ui';

import { ROUTES } from '@/config';
import { ProductSearchProduct } from '@/bff-client';
import { mapProductsToEventItems, trackSelectItemEvent } from '@/utils';

import { useProtectedHeaderSearchContext } from './protected-header-search.context';

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  /**
   * The current search suggestion.
   */
  searchSuggestion: ProductSearchProduct;
}

/**
 * The component holds the individual search suggestion for the search ahead overlay.
 */
export const ProtectedHeaderSearchSuggestion: React.FC<Props> = (props) => {
  const { searchSuggestion, ...rest } = props;
  const { name, sku, brand } = searchSuggestion;

  const { register, index } = useDescendant();

  const { selectedIndex, onSetSelectedIndex, onClearSearch } = useProtectedHeaderSearchContext();

  const selected = index === selectedIndex;

  const handleProductLinkClick = useCallback(() => {
    onClearSearch();
    /**
     * Tracks the select item event when the product link is clicked.
     */
    trackSelectItemEvent(mapProductsToEventItems([props.searchSuggestion]), 'search');
  }, [onClearSearch, props.searchSuggestion]);

  return (
    <Root
      role="option"
      tabIndex={-1}
      aria-selected={selected}
      onMouseLeave={() => onSetSelectedIndex(-1)}
      onMouseMove={() => onSetSelectedIndex(index)}
      onMouseEnter={() => onSetSelectedIndex(index)}
      {...rest}
    >
      <ProductLink
        title={name}
        ref={register}
        $selected={selected}
        onClick={handleProductLinkClick}
        href={`${ROUTES.PROTECTED.PRODUCT}/${brand}/${encodeURIComponent(sku)}`}
      >
        {name}
      </ProductLink>
    </Root>
  );
};

const Root = styled.li`
  list-style: none;
`;

const ProductLink = styled(Link)<{ $selected: boolean }>`
  ${({ theme, $selected }) => css`
    width: 100%;
    display: inline-block;
    padding: ${theme.getSpacing(3, 6)};

    will-change: background-color;
    transition-property: background-color;
    transition-duration: ${theme.transitions.duration.fast}ms;
    transition-timing-function: ${theme.transitions.easing.easeInOut};

    ${$selected &&
    css`
      background-color: ${theme.colors.background.default};
    `}
  `}
`;
