'use client';

import styled, { css } from 'styled-components';

import { Header, HeaderTopBar, HeaderContent as HeaderContentComponent } from '@/components';

import { ProtectedHeaderLogo } from './protected-header-logo';
import { ProtectedHeaderSearch } from './protected-header-search';
import { ProtectedHeaderActions } from './protected-header-actions';
import { ProtectedHeaderMobileMenu } from './protected-header-mobile-menu';
import { ProtectedHeaderProfileDrawer } from './protected-header-profile-drawer';
import { ProtectedHeaderCategoriesDrawer } from './protected-header-categories-drawer';
import { ProtectedHeaderDesktopMenu } from './protected-header-desktop-menu';
import { ProtectedHeaderMobileStreamer } from './protected-header-mobile-streamer';

/**
 * The component is the header that is behind the authenticated section of the app.
 * It holds additional information such as the search, the profile and the cart.
 */
export const ProtectedHeaderClient: React.FC = () => {
  return (
    <>
      <Header>
        <HeaderTopBar />

        <HeaderContent>
          <TopContainer>
            <ProtectedHeaderMobileMenu />
            <ProtectedHeaderLogo />

            <ProtectedHeaderSearch />

            <ProtectedHeaderActions />
          </TopContainer>

          <ProtectedHeaderDesktopMenu />
        </HeaderContent>

        <ProtectedHeaderMobileStreamer />
      </Header>

      <ProtectedHeaderProfileDrawer />

      <ProtectedHeaderCategoriesDrawer />
    </>
  );
};

const HeaderContent = styled(HeaderContentComponent)`
  padding: 0;
`;

const TopContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    align-items: center;
    gap: ${theme.getSpacing(2)};
    margin: ${theme.getSpacing(4, 0)};
    
    grid-template-rows: auto;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'logo search actions';

    ${theme.breakpoints.below('desktop')} { 
      grid-template-rows: auto auto;
      grid-template-columns: auto 1fr auto;
      grid-template-areas: 
        'mobile-menu logo actions' 
        'search search search';
    });
  `}
`;
