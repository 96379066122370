import styled from 'styled-components';

import { ProtectedHeaderSearchMenu } from '../protected-header-search-menu';
import { ProtectedHeaderSearchInput } from '../protected-header-search-input';
import { ProtectedHeaderSearchSuggestions } from '../protected-header-search-suggestions';
import { useProtectedHeaderSearchContext } from '../protected-header-search.context';
import { ProtectedHeaderSearchSuggestion } from '../protected-header-search-suggestion';

import {
  ProtectedHeaderSearchOverlayConsumer,
  ProtectedHeaderSearchOverlayProvider,
} from './protected-header-search-overlay.context';

/**
 * The component contains the overlay that is triggered updating search query.
 */
export const ProtectedHeaderSearchOverlay: React.FC = (props) => {
  const { searchSuggestions } = useProtectedHeaderSearchContext();

  return (
    <ProtectedHeaderSearchOverlayProvider>
      <ProtectedHeaderSearchOverlayConsumer>
        {({ rootRef, onCloseOverlay }) => (
          <Root {...props} ref={rootRef}>
            <ProtectedHeaderSearchInput />

            <ProtectedHeaderSearchMenu>
              <ProtectedHeaderSearchSuggestions>
                {searchSuggestions.map((searchSuggestion, index) => (
                  <ProtectedHeaderSearchSuggestion
                    onClick={() => onCloseOverlay()}
                    key={`${searchSuggestion.sku}-${index}`}
                    searchSuggestion={searchSuggestion}
                  />
                ))}
              </ProtectedHeaderSearchSuggestions>
            </ProtectedHeaderSearchMenu>
          </Root>
        )}
      </ProtectedHeaderSearchOverlayConsumer>
    </ProtectedHeaderSearchOverlayProvider>
  );
};

export default ProtectedHeaderSearchOverlay;

const Root = styled.div`
  position: relative;
  grid-area: search;
`;
