import React, { useCallback } from 'react';
import styled from 'styled-components';

import {
  Stack,
  Drawer,
  OvenIcon,
  VacuumIcon,
  Typography,
  FridgeIcon,
  WasherIcon,
  CloseIcon,
  IconButton,
  SuctionIcon,
  GridViewIcon,
  AccessoryIcon,
  DrawerContent,
  StandmixerIcon,
  DishwasherIcon,
  DrawerBody as ArcadeDrawerBody,
} from '@whiteaway/ui';

import { ROUTES } from '@/config';
import { useRouter } from '@/hooks';
import { useTranslations } from '@/contexts';
import { DrawerMenu, DrawerMenuItem, DrawerMenuItemLabel } from '@/components/drawer-menu';

import { useProtectedHeader } from '../protected-header.context';

import { ProtectedHeaderCategoriesDrawerItem } from './protected-header-categories-drawer-item';

/**
 * This is mapping up against the relewise id for each of the categories.
 * The id is the only unique thing we have right now for each category.
 */
const ICON_MAPPER = {
  '11': <WasherIcon />,
  '12': <FridgeIcon />,
  '13': <DishwasherIcon />,
  '14': <OvenIcon />,
  '15': <SuctionIcon />,
  '16': <StandmixerIcon />,
  '17': <VacuumIcon />,
  '18': <AccessoryIcon />,
  '19': <GridViewIcon />,
};

/**
 * The component is used to display and navigate the category links in the menu drawer
 */
export const ProtectedHeaderCategoriesDrawer: React.FC = () => {
  const { translations } = useTranslations();
  const texts = translations.layouts.protected.header.categoriesDrawer;

  const drawerRef = React.useRef<HTMLDivElement>(null);

  const { categories, openCategoriesDrawer, onCloseCategoriesDrawer } = useProtectedHeader();

  const router = useRouter();

  /** Clicking links within ProtectedHeaderCategoriesDrawer
   * and ProtectedHeaderProfileDrawer currently fails to activate
   * the Toploader due to stopPropagation used in the Arcade Modal.
   * For now the Toploader is instead activated using router.push
   * TODO BO-956  */
  const handleClick = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      event.preventDefault();

      const href = event.currentTarget.getAttribute('href');

      if (href) {
        router.push(href);
        onCloseCategoriesDrawer();
      }
    },
    [onCloseCategoriesDrawer, router],
  );

  return (
    <Drawer placement="left" open={openCategoriesDrawer} onClose={onCloseCategoriesDrawer}>
      <DrawerContent>
        <DrawerBody ref={drawerRef}>
          <Stack spacing={3}>
            <Stack direction="row" justify="space-between" align="center">
              <Typography variant="h2">{texts.header}</Typography>

              <IconButton size="sm" color="neutral" variant="subtle" onClick={() => onCloseCategoriesDrawer()}>
                <CloseIcon />
              </IconButton>
            </Stack>

            <DrawerMenu>
              <Stack spacing={0}>
                {categories.map((category) => (
                  <ProtectedHeaderCategoriesDrawerItem
                    key={category.id}
                    category={category}
                    drawerRef={drawerRef}
                    onLinkClick={handleClick}
                    prevCategoryName={texts.prevCategoryName}
                    path={`${ROUTES.PROTECTED.CATEGORIES}/${category.slug}`}
                    icon={ICON_MAPPER[category.id as keyof typeof ICON_MAPPER]}
                  />
                ))}
              </Stack>

              <DrawerMenuItem href={ROUTES.PROTECTED.CATEGORIES} onClick={handleClick}>
                <DrawerMenuItemLabel>{texts.allCategoriesLink}</DrawerMenuItemLabel>
              </DrawerMenuItem>
            </DrawerMenu>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const DrawerBody = styled(ArcadeDrawerBody)`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: ${({ theme }) => theme.colors.surface.default};
`;
