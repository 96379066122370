import { GTM_EVENTS } from '@/config';

import { EventItem, ItemListNameType, trackGtmEvent } from '../track-gtm-event';

/**
 * This event is used for tracking the selection of an item.
 */
export type SelectItemEvent = {
  event: GTM_EVENTS.SELECT_ITEM;
  options: {
    /**
     * The item list id. This can be used to differentiate between different lists of items.
     */
    item_list_id?: string;
    /**
     * The item list name. This is used to tell where the event is triggered from.
     */
    item_list_name?: 'categories' | 'search' | (string & {}) | undefined;
    /**
     * The items in the item list.
     */
    items: EventItem[];
  };
};

/**
 * Track the select item event. This is used when the user selects an item from a list of items such as the PDP or the search suggestions.
 * @param items The event items to track
 * @param itemListName The name of the item list from which the user selected an item.
 */
export const trackSelectItemEvent = (items: EventItem[], itemListName: ItemListNameType) => {
  trackGtmEvent({
    event: GTM_EVENTS.SELECT_ITEM,
    options: {
      item_list_name: itemListName,
      items,
    },
  });
};
