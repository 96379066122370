'use client';

import {
  useRef,
  useState,
  RefObject,
  useContext,
  useCallback,
  ChangeEvent,
  createContext,
  KeyboardEvent,
  PropsWithChildren,
  KeyboardEventHandler,
} from 'react';

import { useDebounceCallback, useDescendants } from '@whiteaway/ui';

import { ProductSearch, ProductSearchProduct } from '@/bff-client';
import { ROUTES } from '@/config';
import { useRouter } from '@/hooks';
import { trackSearchEvent } from '@/utils';

import { trackSearchTerm } from './protected-header-search.actions';

const MIN_SEARCH_LENGTH = 1;
const DEBOUNCE_TIMEOUT = 300;
const EMPTY_SELECTED_INDEX = -1;

export interface ProtectedHeaderSearchContextProps extends PropsWithChildren {}

export interface ProtectedHeaderSearchContextValues {
  /**
   * The current value for the input.
   */
  inputValue: string;
  /**
   * The ref to the input field.
   */
  inputRef: RefObject<HTMLInputElement | null>;
  /**
   * Callback that runs whenever the clear icon inside the input is clicked.
   */
  onCloseIconClick: () => void;
  /**
   * Callback that is triggered whenever the input field is blurred.
   */
  onInputBlur: () => void;
  /**
   * Callback that is triggered whenever the value inside the input field changes.
   */
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Callback that is triggered whenever the input is focused.
   */
  onInputFocus: () => void;
  /**
   * Callback that is triggered whenever the input has a keydown event.
   */
  onInputKeydown: (event: KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Callback that is triggered whenever the input icon is clicked.
   */
  onInputIconClick: () => void;
  /**
   * Callback that is is used to set the focused index.
   */
  onSetSelectedIndex: (index: number) => void;
  /**
   * Callback that is triggered whenever a suggestion is clicked.
   */
  onClearSearch: () => void;
  /**
   * The selected index
   */
  selectedIndex: number;
  /**
   * Determines whether the field is currently searching.
   */
  searching: boolean;
  /**
   * The search ahead suggestions for the search.
   */
  searchSuggestions: ProductSearchProduct[];
}

export const ProtectedHeaderSearchContext = createContext({} as ProtectedHeaderSearchContextValues);

export const ProtectedHeaderSearchConsumer = ProtectedHeaderSearchContext.Consumer;

export const ProtectedHeaderSearchProvider = (props: ProtectedHeaderSearchContextProps): React.ReactElement => {
  const { children } = props;

  const inputFocusedRef = useRef(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const router = useRouter();

  const abortControllerRef = useRef<AbortController | null>(null);

  const [inputValue, setInputValue] = useState('');

  const [searching, setSearching] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(EMPTY_SELECTED_INDEX);

  const [searchSuggestions, setSearchSuggestions] = useState<ProductSearchProduct[]>([]);

  const { descendants } = useDescendants();

  const fetchSuggestions = useDebounceCallback(async (value: string) => {
    setSearching(true);

    abortControllerRef.current?.abort();

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const response = await fetch(`/api/search?term=${value}`, { signal: abortController.signal });

      /**
       * Relewise tracking of search value
       */
      trackSearchTerm(value);
      /**
       * GTM tracking of search value
       */
      trackSearchEvent(value);

      const body: ProductSearch = await response.json();

      setSearching(false);
      setSearchSuggestions(body.products);
    } catch {
      setSearching(false);
      setSearchSuggestions([]);
    }
  }, DEBOUNCE_TIMEOUT);

  const handleClearSearch = useCallback(() => {
    setInputValue('');

    setSearchSuggestions([]);

    abortControllerRef?.current?.abort();
  }, []);

  const handleSearch = useCallback(() => {
    if (inputValue.length < MIN_SEARCH_LENGTH) return;

    router.push(`${ROUTES.PROTECTED.SEARCH}/${inputValue}`);

    handleClearSearch();
    inputRef.current?.blur();
  }, [inputValue, router, handleClearSearch]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      setInputValue(value);

      if (value.length > MIN_SEARCH_LENGTH) return fetchSuggestions(value);

      /**
       * If we get below the minimum numbers of characters while searching, we want to reset everything.
       */
      if (searching) handleClearSearch();
    },
    [handleClearSearch, fetchSuggestions, searching],
  );

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const keyMapper: Record<string, KeyboardEventHandler> = {
        Enter: () => {
          /**
           * Blur the input field programmatically.
           */
          inputRef.current?.blur();

          /**
           * If no suggestion is selected we want to do a hard search and navigate to the search page.
           */
          if (selectedIndex === EMPTY_SELECTED_INDEX) return handleSearch();

          /**
           * If we have a selected suggestion, we programmatically click on it to trigger its href and go to the PDP page,
           * and reset all the appropriate values.
           */
          const node = descendants.getItem(selectedIndex)?.node as HTMLElement;
          node.click();

          handleClearSearch();
          setSelectedIndex(EMPTY_SELECTED_INDEX);
        },
        ArrowDown: () => {
          if (descendants.isLastIndex(selectedIndex)) return;

          setSelectedIndex(selectedIndex + 1);
        },
        ArrowUp: () => {
          if (descendants.isFirstIndex(selectedIndex)) return;

          setSelectedIndex(selectedIndex - 1);
        },
      };

      const action = keyMapper[event.key];

      if (action) {
        event.preventDefault();
        action(event);
      }
    },
    [selectedIndex, handleSearch, descendants, handleClearSearch],
  );

  const handleCloseIconClick = useCallback(() => {
    handleClearSearch();
  }, [handleClearSearch]);

  const handleInputFocus = useCallback(() => {
    inputFocusedRef.current = true;
  }, []);

  const handleInputBlur = useCallback(() => {
    inputFocusedRef.current = false;
  }, []);

  return (
    <ProtectedHeaderSearchContext.Provider
      value={{
        inputRef,
        searching,
        inputValue,
        selectedIndex,
        searchSuggestions,
        onInputBlur: handleInputBlur,
        onInputIconClick: handleSearch,
        onInputFocus: handleInputFocus,
        onInputChange: handleInputChange,
        onInputKeydown: handleInputKeyDown,
        onSetSelectedIndex: setSelectedIndex,
        onClearSearch: handleClearSearch,
        onCloseIconClick: handleCloseIconClick,
      }}
    >
      {children}
    </ProtectedHeaderSearchContext.Provider>
  );
};

export const useProtectedHeaderSearchContext = (): ProtectedHeaderSearchContextValues => {
  return useContext(ProtectedHeaderSearchContext);
};
