import React from 'react';

import { Input, InputProps, mergeRefs } from '@whiteaway/ui';

import { useTranslations } from '@/contexts';

import { ProtectedHeaderSearchInputAdornment } from './protected-header-search-input-adornment';
import { useProtectedHeaderSearchContext } from './protected-header-search.context';
interface Props extends InputProps {}

/**
 * The component holds the input for the search inside the header.
 */
export const ProtectedHeaderSearchInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { translations } = useTranslations();
  const texts = translations.layouts.protected.header.search.input;

  const { inputValue, onInputChange, onInputBlur, onInputFocus, onInputKeydown, inputRef } =
    useProtectedHeaderSearchContext();

  return (
    <Input
      ref={mergeRefs(ref, inputRef)}
      autoComplete="off"
      value={inputValue}
      onBlur={onInputBlur}
      onFocus={onInputFocus}
      onChange={onInputChange}
      onKeyDown={onInputKeydown}
      placeholder={texts.placeholder}
      endAdornment={<ProtectedHeaderSearchInputAdornment />}
      {...props}
    />
  );
});

ProtectedHeaderSearchInput.displayName = 'ProtectedHeaderSearchInput';
