import styled from 'styled-components';

import { ButtonUnstyled, CloseIcon, Divider, SearchIcon, Spinner, Stack, StackProps } from '@whiteaway/ui';

import { useProtectedHeaderSearchContext } from './protected-header-search.context';

interface Props extends StackProps, React.HTMLAttributes<HTMLDivElement> {}

/**
 * The component contains the icons or adornments for the search input inside the header.
 */
export const ProtectedHeaderSearchInputAdornment: React.FC<Props> = (props) => {
  const { searching, onInputIconClick, inputValue, onCloseIconClick } = useProtectedHeaderSearchContext();

  return (
    <Root direction="row" spacing={2} {...props}>
      {inputValue && (
        <>
          <ButtonUnstyled aria-label="close-search-button" onClick={onCloseIconClick}>
            <CloseIcon />
          </ButtonUnstyled>

          <Divider orientation="vertical" />
        </>
      )}

      {searching ? (
        <Spinner size="small" />
      ) : (
        <ButtonUnstyled aria-label="search-button" onClick={onInputIconClick}>
          <SearchIcon />
        </ButtonUnstyled>
      )}
    </Root>
  );
};

const Root = styled(Stack)`
  height: 24px;
`;
