import React, { useCallback } from 'react';
import styled from 'styled-components';

import { AccountCircleIcon, ShoppingCartIcon, Stack } from '@whiteaway/ui';

import { testIds } from '@/tests';
import { ROUTES } from '@/config';
import { HeaderLink, HeaderLinkLabel } from '@/components';
import { trackViewCartEvent } from '@/utils';
import { useCart, useTranslations } from '@/contexts';

import { useProtectedHeader } from '../protected-header.context';

import { ProtectedHeaderActionsCounter } from './protected-header-actions-counter';

/**
 * The component holds the actions (profile and cart links) that are displayed at the right side of the header.
 */
export const ProtectedHeaderActions: React.FC = () => {
  const { translations } = useTranslations();
  const texts = translations.layouts.protected.header;

  const { cart } = useCart();

  const { onOpenProfileDrawer } = useProtectedHeader();

  const handleCartLinkClick = useCallback(() => {
    if (cart) {
      /**
       * Send the view_cart tracking event to GTM, when the user clicks the cart link.
       */
      trackViewCartEvent(cart);
    }
  }, [cart]);

  return (
    <Root direction="row">
      <HeaderLink onClick={onOpenProfileDrawer} asElement="button">
        <AccountCircleIcon />
        <HeaderLinkLabel>{texts.profileLink}</HeaderLinkLabel>
      </HeaderLink>

      <HeaderLink
        href={ROUTES.PROTECTED.CART}
        onClick={handleCartLinkClick}
        data-testid={testIds.components.protectedHeader.cartLink}
      >
        <ShoppingCartIcon />
        <ProtectedHeaderActionsCounter>
          <HeaderLinkLabel>{texts.cartLink}</HeaderLinkLabel>
        </ProtectedHeaderActionsCounter>
      </HeaderLink>
    </Root>
  );
};

const Root = styled(Stack)`
  grid-area: actions;
`;
