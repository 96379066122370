'use client';

import { Alert, AlertContent, AlertIcon } from '@whiteaway/ui';

import { useErrorBanner, useTranslations } from '@/contexts';

/**
 * The component displays an error message in a banner on top of the page.
 */
export const ErrorBanner: React.FC = () => {
  const { errorBanner } = useErrorBanner();

  const { translations } = useTranslations();

  const texts = translations.components.errorBanner;

  if (!errorBanner) return null;

  return (
    <Alert status="error" variant="subtle">
      <AlertIcon />
      <AlertContent>{texts.message}</AlertContent>
    </Alert>
  );
};
