import { sendGTMEvent } from '@next/third-parties/google';

import {
  ViewItemListEvent,
  ViewItemEvent,
  BeginCheckoutEvent,
  AddToCartEvent,
  AddShippingInfoEvent,
  RemoveFromCartEvent,
  SelectItemEvent,
  PurchaseEvent,
  ViewCartEvent,
  SearchEvent,
} from './events';

/**
 * Type used in events where the item_list_name property is used.
 */
export type ItemListNameType = 'categories' | 'search' | (string & {});

/**
 * Type used in events where the item_list_sorting property is used.
 */
export type ItemListSortingType = 'priceDescending' | 'priceAscending' | 'recommended' | (string & {});

export type EventItem = {
  /**
   * The procuct SKU
   */
  item_id: string;
  /**
   * The item name
   */
  item_name: string;
  /**
   * The price of the item
   */
  price?: number;
  /**
   * The brand of the item
   */
  item_brand?: string;
  /**
   * The category of the item
   */
  item_category?: string;
  /**
   * The category of the item
   */
  item_category2?: string;
  /**
   * The category of the item
   */
  item_category3?: string;
  /**
   * The category of the item
   */
  item_category4?: string;
  /**
   * The category of the item
   */
  item_category5?: string;
  /**
   * The quantity of the item
   */
  quantity?: number;
  /**
   * The index of the item in the list. Use this to track where the item is in the list.
   */
  index?: number;
};

export type GtmEvent =
  | ViewItemEvent
  | ViewItemListEvent
  | BeginCheckoutEvent
  | AddToCartEvent
  | PurchaseEvent
  | AddShippingInfoEvent
  | RemoveFromCartEvent
  | ViewCartEvent
  | SelectItemEvent
  | SearchEvent;

/**
 * The function sends the event to Google Tag Manager.
 * It it a wrapper around the sendGTMEvent function from the @next/third-parties package.
 * @param event The event to send.
 */
export const trackGtmEvent = (params: GtmEvent) => {
  const { event, options = {} } = params;

  sendGTMEvent({ event, ecommerce: options });
};
