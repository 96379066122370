import styled, { css } from 'styled-components';

import { Typography, TypographyProps } from '@whiteaway/ui';

interface Props extends TypographyProps {}

/**
 * The component holds the label for each of the menu items inside the categories and profile drawer.
 */
export const DrawerMenuItemLabel: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  return (
    <Root asElement="span" {...rest}>
      {children}
    </Root>
  );
};

const Root = styled(Typography)`
  ${({ theme }) => css`
    white-space: nowrap;
    border-bottom: 2px solid transparent;
    will-change: border-color;
    transition-property: border-color;
    transition-duration: ${theme.transitions.duration.standard}ms;
    transition-timing-function: ${theme.transitions.easing.sharp};
  `};
`;
