import { DescendantsProvider, Stack, StackProps } from '@whiteaway/ui';

interface Props extends React.PropsWithChildren, StackProps {}

/**
 * The component holds the outer menu for the drawers inside the protected header.
 * It uses the descendants provider to enable keyboard navigation for the menu items.
 */
export const ProtectedHeaderDrawerMenu: React.FC<Props> = (props) => {
  const { children, spacing = 0, ...rest } = props;

  return (
    <DescendantsProvider>
      <Stack role="menu" spacing={spacing} {...rest}>
        {children}
      </Stack>
    </DescendantsProvider>
  );
};
