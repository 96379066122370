import { HTMLAttributes, KeyboardEvent, KeyboardEventHandler, useCallback } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import { forwardRef, mergeRefs, Stack, useDescendant } from '@whiteaway/ui';

interface Props extends HTMLAttributes<HTMLElement> {}

/**
 * The component renders a single item for the drawer menu.
 */
export const DrawerMenuItem = forwardRef<Props, 'a'>((props, ref) => {
  const { children, asElement = Link, ...rest } = props;

  const { descendants, index, register } = useDescendant();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const keyMapper: Record<string, KeyboardEventHandler> = {
        ArrowDown: () => {
          const nextEnabled = descendants.getNextEnabled(index);

          nextEnabled?.node.focus();
        },
        ArrowUp: () => {
          const prevEnabled = descendants.getPrevEnabled(index);

          prevEnabled?.node.focus();
        },
        Home: () => {
          const firstEnabled = descendants.getFirstEnabled();

          firstEnabled?.node.focus();
        },
        End: () => {
          const lastEnabled = descendants.getLastEnabled();

          lastEnabled?.node.focus();
        },
      };

      const action = keyMapper[event.key];

      if (action) {
        event.preventDefault();
        action(event);
      }
    },
    [descendants, index],
  );

  return (
    <Root
      spacing={3}
      align="center"
      role="menuitem"
      direction="row"
      asElement={asElement}
      onKeyDown={handleKeyDown}
      ref={mergeRefs(ref, register)}
      {...rest}
    >
      {children}
    </Root>
  );
});

const Root = styled(Stack)`
  ${({ theme }) => css`
    position: relative;
    padding: ${theme.getSpacing(5, 4)};
    background-color: ${theme.colors.background.subtle};

    &:hover span {
      border-color: ${theme.colors.text.default};
    }

    &:first-child {
      border-top-left-radius: ${theme.radii.md};
      border-top-right-radius: ${theme.radii.md};
    }

    &:last-child {
      border-bottom-left-radius: ${theme.radii.md};
      border-bottom-right-radius: ${theme.radii.md};
    }

    &:not(:last-child):after {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: ${theme.getSpacing(4)};
      right: ${theme.getSpacing(4)};
      background-color: ${theme.colors.outline.default};
    }
  `};
`;
