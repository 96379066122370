import styled, { css } from 'styled-components';

export const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;

    padding: ${theme.getSpacing(2, 0)};

    background-color: ${theme.colors.surface.default};

    ${theme.breakpoints.above('desktop')} {
      display: none;
    }
  `}
`;
