import { Cart } from '@/bff-client';
import { GTM_EVENTS } from '@/config';

import { EventItem, trackGtmEvent } from '../track-gtm-event';
import { mapCartItemsToEventItems } from '../track-gtm.utils';

/**
 * This event is used to track the view of the cart page.
 */
export type ViewCartEvent = {
  event: GTM_EVENTS.VIEW_CART;
  options: {
    /**
     * The currency of the items.
     */
    currency: 'DKK';
    /**
     * The accumulated value of the items in the cart.
     */
    value: number;
    /**
     * The items in the cart.
     */
    items: EventItem[];
  };
};

/**
 * The function will fire whenever the user click the cart link in the header, since this is considered as a cart view.
 * @param cart The users cart.
 */
export const trackViewCartEvent = (cart: Cart) => {
  const { items, totalPrice } = cart;

  trackGtmEvent({
    event: GTM_EVENTS.VIEW_CART,
    options: { currency: 'DKK', value: totalPrice, items: mapCartItemsToEventItems(items) },
  });
};
