import { useRef, RefObject, useContext, createContext, PropsWithChildren, useEffect } from 'react';

import { useBodyScrollLock, useClickOutside, useDisclosure, useEventListener } from '@whiteaway/ui';

import { useProtectedHeaderSearchContext } from '../protected-header-search.context';

export interface ProtectedHeaderSearchOverlayContextProps extends PropsWithChildren {}

export interface ProtectedHeaderSearchOverlayContextValues {
  /**
   * Callback that is used to close the menu.
   */
  onCloseOverlay: () => void;
  /**
   * Callback that is triggered whenever the overlay is opened.
   */
  onOpenOverlay: () => void;
  /**
   * Determines if the menu with the search results should be open.
   */
  openOverlay: boolean;
  /**
   * The ref pointer for the root of the component.
   */
  rootRef: RefObject<HTMLDivElement | null>;
}

export const ProtectedHeaderSearchOverlayContext = createContext({} as ProtectedHeaderSearchOverlayContextValues);

export const ProtectedHeaderSearchOverlayConsumer = ProtectedHeaderSearchOverlayContext.Consumer;

export const ProtectedHeaderSearchOverlayProvider = (
  props: ProtectedHeaderSearchOverlayContextProps,
): React.ReactElement => {
  const { children } = props;

  const rootRef = useRef<HTMLDivElement>(null);

  const { open: openOverlay, onOpen: onOpenOverlay, onClose: onCloseOverlay } = useDisclosure();

  const { searchSuggestions } = useProtectedHeaderSearchContext();

  useBodyScrollLock({ disabled: !openOverlay });

  useClickOutside({ ref: rootRef as RefObject<HTMLDivElement>, callback: onCloseOverlay, disabled: !openOverlay });

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onCloseOverlay();
    }
  };

  useEventListener('keydown', handleKeyDown);

  // This hook opens the overlay whenever there is any search results/suggestions
  useEffect(() => {
    if (searchSuggestions.length !== 0) {
      onOpenOverlay();
    } else {
      onCloseOverlay();
    }
  }, [onCloseOverlay, onOpenOverlay, searchSuggestions]);

  return (
    <ProtectedHeaderSearchOverlayContext.Provider
      value={{
        rootRef,
        openOverlay,
        onOpenOverlay,
        onCloseOverlay,
      }}
    >
      {children}
    </ProtectedHeaderSearchOverlayContext.Provider>
  );
};

export const useProtectedHeaderSearchOverlayContext = (): ProtectedHeaderSearchOverlayContextValues => {
  return useContext(ProtectedHeaderSearchOverlayContext);
};
