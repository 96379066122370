import { Container, LocalShippingIcon, Stack, Typography } from '@whiteaway/ui';

import { useTranslations } from '@/contexts';

import * as Styled from './protected-header-mobile-streamer.styled';

/**
 * Renders a streamer in the bottom of the header component on mobile devices.
 * Show a streamer with a delivery icon and delivery text.
 */
export const ProtectedHeaderMobileStreamer: React.FC = () => {
  const { translations } = useTranslations();

  const deliveryText = translations.components.header.streamer.delivery;

  return (
    <Styled.Root>
      <Container>
        <Stack direction="row" spacing={1} align="center">
          <LocalShippingIcon size="sm" />

          <Typography variant="caption">{deliveryText}</Typography>
        </Stack>
      </Container>
    </Styled.Root>
  );
};
